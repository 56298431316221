<template>
    <div class="flex flex-row align-items-center">
        <div class="flex-1 grid">
            <div class="field col-12 md:col-3">
                <label for="grupo">Grupo</label>
                <InfinityDropdownGruposPorUsuario
                    id="grupo"
                    v-model="_filtrosExtras.grupo"
                    class="w-full"
                    :filtrosExtras="{ ativo: true }"
                    showClear
                />
            </div>
            <div class="field col-12 md:col-3">
                <label for="cliente">Cliente</label>
                <InfinityDropdownCliente
                    id="cliente"
                    v-model="_filtrosExtras.customer"
                    class="w-full"
                    :filtrosExtras="{ ativo: true, grupoId: _filtrosExtras?.grupo?.id}"
                    showClear
                />
            </div>
            <div class="field col-12 md:col-3">
                <label for="status">Status</label>
                <Dropdown
                    id="status"
                    v-model="_filtrosExtras.status"
                    :options="statusOptions"
                    optionLabel="label"
                    optionValue="value"
                    class="w-full"
                    placeholder="Selecione"
                    showClear
                />
            </div>
            <div class="field col-12 md:col-3 align-self-end">
                <Button label="Remover filtros" icon="pi pi-filter-slash" class="p-button-link" @click="removerFiltros" />
                <Button label="Filtrar" icon="pi pi-filter" class="p-button-outlined" @click="filtrar" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';
import InfinityDropdownCliente from '../../clientes/components/InfinityDropdownCliente';
import InfinityDropdownGruposPorUsuario from '../../grupos/components/InfinityDropdownGruposPorUsuario';
import { useFiltrosCliente } from '../composables/useFiltrosCliente';

const emit = defineEmits(['loadCustomers', 'update:filtrosExtras']);
const props = defineProps({
    filtrosExtras: {
        type: Object
    },
    statusOptions: {
        type: Object
    }
});
const { _filtrosExtras, filtrar, removerFiltros } = useFiltrosCliente(props, emit);
</script>

<style></style>
