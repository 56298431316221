<template>
    <Tag
        :style="tagStatusStyle(props.status)"
        :value="definirDescricaoTagStatus(props.status)"
    />
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    status: {
        type: Object,
        required: true
    }
});

function tagStatusStyle(status) {
    return status?.style;
}

function definirDescricaoTagStatus(status) {
    return status?.label;
}
</script>
