<template>
    <div>
        <div class="flex flex-row justify-content-between align-items-end p-5 pb-0">
            <div>
                <Button label="Voltar" icon="pi pi-chevron-left" class="p-button-link" @click="voltar" />
                <AppBreadcrumb />
                <div class="flex flex-row align-items-center p-1">
                    <AppInfoManual :tooltip="props.tooltip" :nomeTelaDoManual="props.nomeTelaDoManual" />
                    <h3 class="m-0">{{ props.title }}</h3>
                </div>
            </div>
            
            <slot name="extraContent"></slot>
        </div>
        <hr class="mt-5 mb-4" />
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import AppInfoManual from '../../../components/AppInfoManual.vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    tooltip: {
        type: String,
        default: ''
    },
    nomeTelaDoManual: {
        type: String,
        default: ''
    }
});

function voltar() {
    const currentPath = route.path;
    
    if(currentPath === '/gestao-risco/clientes') {
        router.replace('/');
    } else {
        router.go(-1);
    }
}
</script>
