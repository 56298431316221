<template>
    <AppInfinityDropdown
        v-model="value"
        optionLabel="name"
        :filterFields="['name', 'inscricao', 'tradeName']"
        :service="$service"
        :renderValue="(slotProps) => slotProps.value?.integracaoTerceiros ? slotProps.value?.name + ' | CodSoc - ' + slotProps.value?.code : slotProps.value?.name"
        :renderOption="(slotProps) => slotProps.option?.integracaoTerceiros ? slotProps.option?.name + ' | CodSoc - ' + slotProps.option?.code : slotProps.option?.name"
        :filtrosExtras="filtrosExtras"
        :recordsPerPage="recordsPerPage"
        :ordenacao="{'customers.name': 'ASC'}"
    />
</template>
<script>
import BaseService from '@/services/BaseService';
export default {
    name: 'InfinityDropdownCliente',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Object
        },
        recordsPerPage: {
            type: Number,
            default: 20
        },
        filtrosExtras: {
            type: Object
        }
    },
    data() {
        return {
            $service: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new BaseService('/customers');
    }
};
</script>
